body {
  margin: 0;
  background-color: #ffffff;
}

/* html {
  font-size: 13px;
} */

.h-100 {
  margin-top: 100px;
}

.h-50 {
  margin-top: 50px;
}

.bg-c-lite-green {
  background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.form_container {
  width: 30%;
}

@media screen and (max-width: 1000px) {
  .form_container {
    width: 45%;
  }
}

@media screen and (max-width: 700) {
  .form_container {
    width: 65%;
  }
}

.footer {
  min-height: 100px;
  
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

/* breadcrumbs */

.breadcrumbs {
  /* max-width: 1200px; */
  /* margin-top: 10px; */
  padding-left: 105px;
}

.breadcrumbs > *{
  display: inline-block;
  margin-right: 10px;
}

.breadcrumbs .crumb:after {
  content: '>';
  margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after {
  display: none;
}

.breadcrumbs .crumb a {
  color: cornflowerblue;
}

/* testing sidebar */

/* .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
} */
 /* App.css */
 .sidebar {
   height: 100vh;
   padding-top: 1rem;
 }

 .content-area {
   padding-top: 1rem;
 }

 .sidebar .card {
   background-color: #ffffff;
   /* Light background for better readability */
   margin-bottom: 1rem;
 }

/* Styling for RightSidebar */
.sidebar .card-title {
  font-size: 0.875rem;
  /* Small font size for title */
  font-weight: bold;
  line-height: 1.2;
  /* Reduced line height for title */
}

.sidebar .card-text {
  font-size: 0.75rem;
  /* Very small font size for text */
  line-height: 1.2;
  /* Reduced line height for text */
  margin-bottom: 0.4rem;
  /* Optional: Adjusts spacing between items */
}

/* App.css */

/* Square border around the card */
.card {
  border-radius: 0 !important;
}

.table {
  border-collapse: collapse;
}

/* Styling for the header row */
.table thead th {
  background-color: #f8f9fa;
  color: #333;
  /* font-weight: bold; */
  font-size: 0.75rem;
}

/* Optional: Compact padding for table rows */
.table td {
  padding: 0.5rem;
  font-size: 0.75rem;
  border: none; /* Removes internal cell borders */
}

/* Add outer border to the table */
.table {
  border: 1px solid #dee2e6;
  /* Outer border color */
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/* style for td */
.column-max-content {
  max-width: content;
}

.table th:first-child,
.table td:first-child {
  min-width: 100px;
  /* Set a minimum width for the first column */
}

/* make table more compact */
/* Reduce spacing between elements */
.table {
  margin-bottom: 0;
}

.table th,
.table td {
  padding: 0.25rem;
  /* Reduce padding */
}

.form-control {
  padding: 0.25rem 0.5rem;
  /* Reduce padding */
  font-size: 0.875rem;
  /* Decrease font size */
}

/* Reduce padding and margin for form controls */
.form-group {
  margin-bottom: 0.25rem;
  /* Reduce margin */
}

/* Reduce margin for table rows */
.table tr {
  margin-bottom: 0.25rem;
  /* Reduce margin */
}

.input-group-prepend {
  min-width: 150px;
}


#more {
  display: none;
}

/* CSS */
.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.fixed-column {
  width: 50px;
}

.small-font {
  font-size: 12px;
  /* Adjust size as needed */
}

/* styles.css
.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.navbar li a:hover {
  background-color: #111111;
} */

/* .carousel-item {
  background: #777;
} */
 /*  FOR STRIPE */
 
 /* FOR Cookie consent */
 .modal-content {
   border-radius: 10px;
 }

 /* to disable Link */
 .disabled-link {
   color: gray;
   pointer-events: none;
   text-decoration: none;
 }

 /* code for STEPS */
 .form-container {
   max-width: 400px;
   margin: 0 auto;
   text-align: center;
 }

 .progress-indicator {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
 }

 .step {
   text-align: center;
 }

 .step-circle {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background: #ddd;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 auto;
 }

 .step-circle.completed {
   background: #4caf50;
   color: #fff;
 }

 .form-step {
   margin-bottom: 20px;
 }

 .dob-inputs {
   display: flex;
   gap: 5px;
 }

 .dob-inputs input {
   width: 30%;
 }

 .form-navigation {
   display: flex;
   justify-content: space-between;
 }

 /* Code for input forms */
 .input-form {
  background-color: #fbfbfb;
  padding: 20px;
 }