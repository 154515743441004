.custom-card {
    font-size: 0.85rem; /* Smaller font size for the entire card */
    border: none;
}

.custom-header {
    font-size: 0.9rem;
    font-weight: normal;
}

.custom-list-group {
    border: none;
}

.custom-list-item {
    border: none; /* Removes border lines between items */
    font-weight: normal; /* Removes bold styling */
    font-size: 0.85rem; /* Reduces font size for list items */
    padding: 0.3rem 0.5rem; /* Adds padding for a cleaner look */
    color: #333; /* Optional: Adjust text color if needed */
}
